import img1 from "./assets/images/img1.png";
import logo1 from "./assets/icons/1.svg";
import logo2 from "./assets/icons/2.svg";
import img2 from "./assets/images/img2.png";
import img3 from "./assets/images/img3.png";

/*

1 Breaking Bank

2 The Big Bank Misery 

3 How to get away with funding
 
4 Better Call Salt

5 Tax Education 

6 Man vs Fee

7 Money Heist

8 Peaky Bankers

9 Banking: A series of unfortunate events

10 House of Cards

*/

const data = [
  {
    id: 1,
    name: "Breaking Bad",
    description:
      "Diagnosed with terminally ill lung cancer, a high school teacher resorts to looting a bank to provide for the family.",
    rating: 4,
    year: 2008,
    seasons: 5,
    genre: "Drama",
    image: "../assets/images/img1.png",
    logo: "../assets/icons/1.svg",
  },
  {
    id: 2,
    name: "The Big Bank Misery",
    description:
      "When a pretty girl Penny is seen at a bank, socially awkward banker Leonard gets smitten, much to his roommate Sheldon’s dismay.",
    rating: 4,
    year: 2007,
    seasons: 12,
    genre: "Sitcom",
    image: img2,
    logo: logo2,
  },
  {
    id: 3,
    name: "How to get away with funding",
    description:
      "A tricky Chartered Accountant challenges her brightest students to solve funding for top start-ups, but scholars pay a high price for impressing her.",
    rating: 4,
    year: 2014,
    seasons: 6,
    genre: "Drama",
    image: img3,
    logo: logo1,
  },
  {
    id: 4,
    name: "Better Call SALT",
    description:
      "Lawyer Jimmy McGill tries to leave his seedy past behind him and help people with bank-related queries.",
    rating: 4,
    year: 2015,
    seasons: 6,
    genre: "Drama",
    image: img2,
    logo: logo2,
  },
  {
    id: 5,
    name: "Tax Education",
    description:
      "Frugal Otis has all the answers when it comes to tax advice, thanks to his CA mom. So rebel Maeve proposes a school tax-education department.",
    rating: 4,
    year: 2019,
    seasons: 2,
    genre: "Drama",
    image: img1,
    logo: logo1,
  },
  {
    id: 6,
    name: "Man Vs Fee",
    description:
      "Thrifty dad Trevor tries to get the best of fee while guarding a posh bank— but only unleashes more hidden fees in this comedy series.",
    rating: 4,
    year: 2022,
    seasons: 1,
    genre: "Comedy",
    image: "../assets/images/img1.png",
    logo: "../assets/icons/1.svg",
  },
  {
    id: 7,
    name: "Money Heist",
    description:
      "Eight capitalists take hostages and lock themselves in the Royal Mint of Spain as a criminal mastermind manipulates the banker to pass his loan.",
    rating: 4,
    year: 2017,
    seasons: 5,
    genre: "Thriller",
    image: img2,
    logo: logo2,
  },
  {
    id: 8,
    name: "Peaky Bankers",
    description:
      "A notorious gang of bankers in 1919 Birmingham, England, is led by the fierce Tommy Shelby, a crime boss set on moving up in the world no matter the cost.",
    rating: 4,
    year: 2013,
    seasons: 6,
    genre: "Drama",
    image: img3,
    logo: logo1,
  },
  {
    id: 9,
    name: "Banking: A Series of unfortunate events",
    description:
      "An extraordinary Baudelaire orphans face trials, tribulations, the evil Count Olaf, and their fateful quest to unlock long-held family banking connections.",
    rating: 4,
    year: 2017,
    seasons: 3,
    genre: "Mystery",
    image: img2,
    logo: logo2,
  },
  {
    id: 10,
    name: "House of CARDS",
    description:
      "A ruthless trader will stop at nothing to conquer, The Wall Street, in this reward winning political drama.",
    rating: 4,
    year: 2013,
    seasons: 6,
    genre: "Drama",
    image: img1,
    logo: logo1,
  },
];

export default data;
