import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "../assets/icons/hamburger.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import Bell from "../assets/icons/bell.svg";

export default function NavMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          // backgroundColor: "white",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          justifyContent: "center",
        }}
      >
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              src={MenuIcon}
              alt="menu icon"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "& .MuiMenuItem-root": {
              minWidth: "200px",
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem>Home</MenuItem>
        <MenuItem>FDI</MenuItem>
        <MenuItem>IR</MenuItem>
        <MenuItem>Cards</MenuItem>
        <MenuItem>My List</MenuItem>
        <MenuItem>Rewards</MenuItem> */}
        <MenuItem className="flex gap-4">
          {/* <img src={Bell} alt="bell" /> */}
          <a href={"https://salt.pe"} target="_blank" rel="noreferrer">
            By SALT 🧂
          </a>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
