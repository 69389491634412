import React from "react";
import { useMediaQuery } from "react-responsive";
import Bell from "../assets/icons/bell.svg";
import NavMenu from "./Menu";
import SaltLogo from "./../assets/icons/bankflix.png";

const Header = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isSmallDevice = useMediaQuery({ query: "(max-width: 700px)" });

  return (
    <div
      className="fixed top-0 flex flex-row justify-between w-full  h-28 items-center"
      style={{
        padding: isSmallDevice
          ? "0px 30px"
          : isTabletOrMobile
          ? "0px 50px"
          : "0px 80px",
      }}
    >
      <div className="flex gap-16">
        <img src={SaltLogo} alt="BANKFLIX" style={{ height: "36px" }} />
        {isDesktopOrLaptop ? (
          <nav className="flex gap-14 flex-wrap items-center text-lg">
            {/* <a href="home">Home</a>
            <a href="home">FDI</a>
            <a href="home"> IR</a>
            <a href="as">Cards</a>
            <a href="as">My List</a>
            <a href="rewards"> Rewards </a> */}
          </nav>
        ) : null}
      </div>
      {isDesktopOrLaptop ? (
        <div className="flex gap-4">
          {/* <img src={Bell} alt="bell" /> */}
          <a href={"https://salt.pe"} target="_blank" rel="noreferrer">
            By SALT 🧂
          </a>
        </div>
      ) : (
        <NavMenu />
      )}
    </div>
  );
};

export default Header;
