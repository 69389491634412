import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const BootstrapButtonCheckout = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "14px 24px",
  lineHeight: 1.5,
  background: "linear-gradient(180deg, #1E5EF3 0%, #134FDA 100%)",
  color: "#fff",
  fontFamily: `"Plus Jakarta Sans", sans-serif`,
  borderRadius: "5px",

  "&:active": {
    boxShadow: "none",
    background: "#134FDA",
  },
  "&:hover": {},
});

const BootstrapButtonGray = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 24,
  padding: "14px 24px",
  lineHeight: 1.5,
  background: "rgba(255, 255, 255, 0.07)",
  color: "#fff",
  fontFamily: `"Plus Jakarta Sans", sans-serif`,
  borderRadius: "5px",
  backgroundColor: "#131313",
  "&:active": {
    boxShadow: "none",
    background: "rgb(44,44,44)",
  },
  "&:hover": {
    background: "rgb(44,44,44)",
  },
});

export { BootstrapButtonCheckout, BootstrapButtonGray };
