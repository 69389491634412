import React, { useEffect, useState } from "react";
import data from "../data";
import saltEmojiWh from "./../assets/icons/saltEmoji.svg";
import saltEmojiGray from "./../assets/icons/saltEmojiGray.svg";
import { BootstrapButtonCheckout, BootstrapButtonGray } from "./button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "react-responsive";
import "./Main.css";

// Import Swiper styles
import "swiper/css";
import Gallery from "./Gallery";

const Main = (props) => {
  const [colors, setcolors] = useState([true, true, true, true, true]);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isSmallDevice = useMediaQuery({ query: "(max-width: 700px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  useEffect(() => {
    let i = data[props.selected - 1]?.rating;
    let newColors = [...colors];
    for (let j = 0; j < colors?.length; j++) {
      if (j < i) {
        newColors[j] = true;
      } else {
        newColors[j] = false;
      }
    }
    setcolors(newColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected]);

  return (
    <div
      className="main-contianer flex flex-col"
      style={{
        background:
          "linear-gradient(116.94deg, #000000 25.28%, rgba(0, 0, 0, 0) 43.54%), linear-gradient(246.7deg, rgba(0, 0, 0, 0) 18.46%, #000000 77.85%)",
        justifyContent: "flex-end",
        gap: "3%",
      }}
    >
      <div
        className="gap-4"
        style={{
          display: "flex",
          flexDirection: isDesktopOrLaptop ? "row" : "column",
          padding: isDesktopOrLaptop ? "80px" : isSmallDevice ? "30px" : "50px",
          paddingTop: isDesktopOrLaptop ? "4%" : isSmallDevice ? "16%" : "8%",
          paddingBottom: 0,
          height: "73%",
          // height: isSmallDevice ? "30%" : "auto",
        }}
      >
        <div
          style={{
            width: isDesktopOrLaptop ? "35%" : isSmallDevice ? "100%" : "50%",
            // height: isDesktopOrLaptop ? "55%" : isSmallDevice ? "100%" : "40%",
            height: "100%",
          }}
        >
          <div
            style={{
              height: isDesktopOrLaptop ? "55%" : isSmallDevice ? "35%" : "45%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={require(`../assets/icons/${props.selected}.svg`)}
              alt="movie logo"
              style={{
                width: isDesktopOrLaptop ? "100%" : "60%",
                height: isSmallDevice
                  ? "180px"
                  : isTabletOrMobile
                  ? "220px"
                  : isBigScreen
                  ? "350px"
                  : "250px",
                maxHeight: "100%",
                margin: "auto 0",
                maxWidth: "450px",
              }}
            />
          </div>
          <div
            style={{
              height: "40%",
            }}
          >
            <div
              className="flex "
              style={{
                gap: isSmallDevice ? "5px" : isTabletOrMobile ? "20px" : "32px",
                padding: "2% 0",
              }}
            >
              <div className="flex gap-1 items-center">
                {colors.map((color, index) => {
                  return color ? (
                    <img
                      src={saltEmojiWh}
                      alt="salt emoji"
                      key={index}
                      style={{ height: "15px" }}
                    />
                  ) : (
                    <img
                      src={saltEmojiGray}
                      alt="salt emoji"
                      key={index}
                      style={{ height: "15px" }}
                    />
                  );
                })}
              </div>
              <p className="text-base">{data[props.selected - 1]?.year}</p>
              <p className="text-base">{data[props.selected - 1]?.genre}</p>
              <p className="text-base">{`${
                data[props.selected - 1]?.seasons
              } Seasons`}</p>
            </div>
            <p className="text-base" style={{ marginTop: "15px" }}>
              {data[props.selected - 1]?.description}
            </p>
            <br />
            <p>Share BANKFLIX on your favorite social media</p>
            <div className="flex gap-8 py-3" style={{ margin: "25px 0" }}>
              {/* <BootstrapButtonCheckout disableRipple>
                Share
              </BootstrapButtonCheckout> */}
              <BootstrapButtonGray
                onClick={() => {
                  window.open(
                    "http://www.facebook.com/sharer.php?u=" +
                      encodeURIComponent("https://bankflix.xyz/") +
                      "&t=" +
                      encodeURIComponent(
                        "Happy 25th Anniversary to the ‘chillest company’ @netflix from coolest one @saltpe_ https://bankflix.xyz"
                      ),
                    "sharer",
                    "toolbar=0,status=0,width=626,height=436"
                  );
                }}
              >
                <FontAwesomeIcon icon={faFacebookF} color="royalblue" />
              </BootstrapButtonGray>
              <BootstrapButtonGray
                onClick={() => {
                  window.open(
                    "http://twitter.com/share?text=Watch out 👀 for these cool shows because your eyes ( @Netflix ) and bank ( @saltpe_ 🧂), both need balance.&url=" +
                      encodeURIComponent("https://bankflix.xyz/"),
                    "sharer",
                    "toolbar=0,status=0,width=626,height=436"
                  );
                }}
              >
                <FontAwesomeIcon icon={faTwitter} color="deepskyblue" />
              </BootstrapButtonGray>
              <BootstrapButtonGray
                href={
                  "whatsapp://send?text=" +
                  encodeURIComponent(
                    "Watch out 👀 for these cool shows because your eyes ( @Netflix ) and bank ( @saltpe_ SALT 🧂 ), both need balance. https://bankflix.xyz"
                  )
                }
              >
                <FontAwesomeIcon icon={faWhatsapp} color="forestgreen" />
              </BootstrapButtonGray>
              {/* <BootstrapButtonGray>More Info</BootstrapButtonGray> */}
            </div>

            <a
              href="https://salt.pe"
              target="_blank"
              rel="noreferrer"
              className="text-base"
              style={{
                fontSize: "14px",
              }}
            >
              Learn how SALT can help you manage your international
              transactions!
            </a>
          </div>
        </div>
      </div>
      <Gallery handleClick={props.handleClick} />
    </div>
  );
};

export default Main;
