import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import data from "../data";
import { useMediaQuery } from "react-responsive";

const Gallery = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isSmallDevice = useMediaQuery({ query: "(max-width: 700px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div
      style={{
        // height: isSmallDevice ? "140px" : "180px",
        marginLeft: isTabletOrMobile ? "0" : "30%",
        height: "18%",
        display: "flex",
        alignItems: "center",
        paddingBottom: "1%",
      }}
    >
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        spaceBetween={10}
        centeredSlides={true}
        autoHeight={true}
        // freeModeSticky={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        initialSlide={0}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 5,
          stretch: 5,
          depth: 150,
          modifier: 2,
          slideShadows: true,
        }}
        height={"17%"}
        className="mySwiper"
        onClick={(slide) => {
          // console.log(slide.activeIndex);
          if (slide?.activeIndex >= 0 && slide?.activeIndex < data?.length) {
            // props.handleClick(slide?.clickedIndex + 1);
            slide.slideTo(slide?.clickedIndex);
          }
        }}
        // onSliderMove={(move) => {
        //   if (move?.activeIndex >= 0 && move?.activeIndex <= 6)
        //     // props.handleClick(move?.activeIndex + 1);
        // }}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        navigation={true}
        onActiveIndexChange={(index) => {
          // console.log(index);

          if (index?.activeIndex >= 0 && index?.activeIndex < data?.length) {
            props.handleClick(index?.activeIndex + 1);
          }
        }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                width: "max-content",
              }}
            >
              {(isActive) => {
                return (
                  <div
                    style={{
                      height: "16vh",
                      width: "16vh",
                      // border: "4px solid #fff",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={require(`../assets/images/${index + 1}.png`)}
                      alt="movie logo"
                      style={{
                        height: "100%",
                        borderRadius: "12px",
                        border: "4px solid #fff",
                      }}
                    />
                  </div>
                );
              }}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Gallery;
