import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import React, { useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4Ps_hU94lJuZl5_DuLKRKHijlnx6RZjI",
  authDomain: "salt-pe.firebaseapp.com",
  projectId: "salt-pe",
  storageBucket: "salt-pe.appspot.com",
  messagingSenderId: "591314812002",
  appId: "1:591314812002:web:5c3112e74704e07272e53b",
  measurementId: "G-8K9JLJJDZQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [selected, setSelected] = useState(1);

  const handleClick = (id) => {
    // console.log(id);
    setSelected(id);
  };

  return (
    <div
      className="App"
      style={{
        background: `url(${require(`./assets/images/img${selected}.png`)}) no-repeat center center/cover`,
        boxShadow: "inset 0 50px 300px #000, inset 0 -50px 300px #000",
      }}
    >
      <iframe
        title="IntroSound"
        src="intro.mp3"
        allow="autoplay"
        style={{ display: "none" }}
        id="audio"
      />
      <audio autoPlay>
        <source src="intro.mp3" type="audio/mpeg" />
      </audio>
      <Header />
      {/* <!-- Edit the letter attr to: N, E, T, F, L, I or X --> */}
      {/* <div id="container">
        <netflixintro letter="N">
          <div class="helper-1">
            <div class="effect-brush">
              <span class="fur-31"></span>
              <span class="fur-30"></span>
              <span class="fur-29"></span>
              <span class="fur-28"></span>
              <span class="fur-27"></span>
              <span class="fur-26"></span>
              <span class="fur-25"></span>
              <span class="fur-24"></span>
              <span class="fur-23"></span>
              <span class="fur-22"></span>
              <span class="fur-21"></span>
              <span class="fur-20"></span>
              <span class="fur-19"></span>
              <span class="fur-18"></span>
              <span class="fur-17"></span>
              <span class="fur-16"></span>
              <span class="fur-15"></span>
              <span class="fur-14"></span>
              <span class="fur-13"></span>
              <span class="fur-12"></span>
              <span class="fur-11"></span>
              <span class="fur-10"></span>
              <span class="fur-9"></span>
              <span class="fur-8"></span>
              <span class="fur-7"></span>
              <span class="fur-6"></span>
              <span class="fur-5"></span>
              <span class="fur-4"></span>
              <span class="fur-3"></span>
              <span class="fur-2"></span>
              <span class="fur-1"></span>
            </div>
            <div class="effect-lumieres">
              <span class="lamp-1"></span>
              <span class="lamp-2"></span>
              <span class="lamp-3"></span>
              <span class="lamp-4"></span>
              <span class="lamp-5"></span>
              <span class="lamp-6"></span>
              <span class="lamp-7"></span>
              <span class="lamp-8"></span>
              <span class="lamp-9"></span>
              <span class="lamp-10"></span>
              <span class="lamp-11"></span>
              <span class="lamp-12"></span>
              <span class="lamp-13"></span>
              <span class="lamp-14"></span>
              <span class="lamp-15"></span>
              <span class="lamp-16"></span>
              <span class="lamp-17"></span>
              <span class="lamp-18"></span>
              <span class="lamp-19"></span>
              <span class="lamp-20"></span>
              <span class="lamp-21"></span>
              <span class="lamp-22"></span>
              <span class="lamp-23"></span>
              <span class="lamp-24"></span>
              <span class="lamp-25"></span>
              <span class="lamp-26"></span>
              <span class="lamp-27"></span>
              <span class="lamp-28"></span>
            </div>
          </div>
          <div class="helper-2">
            <div class="effect-brush">
              <span class="fur-31"></span>
              <span class="fur-30"></span>
              <span class="fur-29"></span>
              <span class="fur-28"></span>
              <span class="fur-27"></span>
              <span class="fur-26"></span>
              <span class="fur-25"></span>
              <span class="fur-24"></span>
              <span class="fur-23"></span>
              <span class="fur-22"></span>
              <span class="fur-21"></span>
              <span class="fur-20"></span>
              <span class="fur-19"></span>
              <span class="fur-18"></span>
              <span class="fur-17"></span>
              <span class="fur-16"></span>
              <span class="fur-15"></span>
              <span class="fur-14"></span>
              <span class="fur-13"></span>
              <span class="fur-12"></span>
              <span class="fur-11"></span>
              <span class="fur-10"></span>
              <span class="fur-9"></span>
              <span class="fur-8"></span>
              <span class="fur-7"></span>
              <span class="fur-6"></span>
              <span class="fur-5"></span>
              <span class="fur-4"></span>
              <span class="fur-3"></span>
              <span class="fur-2"></span>
              <span class="fur-1"></span>
            </div>
          </div>
          <div class="helper-3">
            <div class="effect-brush">
              <span class="fur-31"></span>
              <span class="fur-30"></span>
              <span class="fur-29"></span>
              <span class="fur-28"></span>
              <span class="fur-27"></span>
              <span class="fur-26"></span>
              <span class="fur-25"></span>
              <span class="fur-24"></span>
              <span class="fur-23"></span>
              <span class="fur-22"></span>
              <span class="fur-21"></span>
              <span class="fur-20"></span>
              <span class="fur-19"></span>
              <span class="fur-18"></span>
              <span class="fur-17"></span>
              <span class="fur-16"></span>
              <span class="fur-15"></span>
              <span class="fur-14"></span>
              <span class="fur-13"></span>
              <span class="fur-12"></span>
              <span class="fur-11"></span>
              <span class="fur-10"></span>
              <span class="fur-9"></span>
              <span class="fur-8"></span>
              <span class="fur-7"></span>
              <span class="fur-6"></span>
              <span class="fur-5"></span>
              <span class="fur-4"></span>
              <span class="fur-3"></span>
              <span class="fur-2"></span>
              <span class="fur-1"></span>
            </div>
          </div>
          <div class="helper-4">
            <div class="effect-brush">
              <span class="fur-31"></span>
              <span class="fur-30"></span>
              <span class="fur-29"></span>
              <span class="fur-28"></span>
              <span class="fur-27"></span>
              <span class="fur-26"></span>
              <span class="fur-25"></span>
              <span class="fur-24"></span>
              <span class="fur-23"></span>
              <span class="fur-22"></span>
              <span class="fur-21"></span>
              <span class="fur-20"></span>
              <span class="fur-19"></span>
              <span class="fur-18"></span>
              <span class="fur-17"></span>
              <span class="fur-16"></span>
              <span class="fur-15"></span>
              <span class="fur-14"></span>
              <span class="fur-13"></span>
              <span class="fur-12"></span>
              <span class="fur-11"></span>
              <span class="fur-10"></span>
              <span class="fur-9"></span>
              <span class="fur-8"></span>
              <span class="fur-7"></span>
              <span class="fur-6"></span>
              <span class="fur-5"></span>
              <span class="fur-4"></span>
              <span class="fur-3"></span>
              <span class="fur-2"></span>
              <span class="fur-1"></span>
            </div>
          </div>
        </netflixintro>
      </div> */}
      <Main selected={selected} handleClick={handleClick} />
    </div>
  );
}

export default App;
